html,
body,
#root {
  min-height: 100%;
  height: 100%;
}

body {
  background-color: rgb(241, 245, 249);
  overflow: hidden;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none !important;
}
.hide-scrollbar {
  -ms-overflow-style: none !important;
}
.search-result-style::-webkit-scrollbar {
  display: none;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.animate-slide-in {
  animation: slideIn 0.25s ease-in;
}

.animate-slide-out {
  animation: slideOut 0.25s ease-out;
}

.hideScrollbar::-webkit-scrollbar {
  width: 10px;
}

.hideIncDescBtn::-webkit-inner-spin-button,
.hideIncDescBtn::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.borderr {
  border: 2px solid red;
}
.tab-work-complete-page {
  .press-close-option {
    display: none;
  }
  .tabs {
    button {
      margin-left: 0;
      border-radius: 5px;
      background-color: #f1f5f9;
      &.btn-active {
        border: 1px solid #0081ff;
        background-color: white;
      }
    }
  }
  .phase-breakdown-modal {
    thead th:nth-child(3),
    td[headers="Units Complete"] {
      display: none;
    }
  }
  .select-phase-codes-ctn {
    height: auto;
    .select-phase-codes-section {
      max-height: 515px;
    }
  }
  .select-scale-ctn {
    top: 4.5rem;
    .select-scale-section {
      height: auto;
    }
  }

  .tab-back-btn {
    display: block;
  }
}
.not-allowed{
  cursor: not-allowed;
}